import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// utils
import { isPreviewSelector } from 'utils/selectors/environmentSelectors';
import { Log } from 'services/log';

const selectFn = process.env.JEST_WORKER_ID !== undefined ? () => {} : useSelector;
/**
 * Hook for accessing the GA datalayer
 * and logs for debugging purposes when on local or preview environment
 */
export function useTracking() {
  const isPreview = selectFn(isPreviewSelector);

  return useMemo(
    () => ({
      trackEvent: (data) => {
        if (
          (window && ['local.web.geberit.com', 'localhost'].includes(window.location.hostname)) ||
          isPreview
        ) {
          Log.info('TRACKING EVENT', data);
        }

        if (Array.isArray(window.dataLayer)) {
          window.dataLayer?.push(data);
        }
      },
    }),
    [isPreview],
  );
}
